function toggleMenu() {
  if( $('#menuItems,#scrim').css('display') == 'block' ){
      $('#menuItems,#scrim').css('display', 'none')  ;
  }else{
      $('#menuItems,#scrim').css('display', 'block') ;
  }
}

document.body.querySelectorAll(".offer-box").forEach(function(node,i){
   node.classList.add(i % 2?"even":"odd");
})

var quoteBox = document.body.querySelectorAll(".quote-box");
var classes = ["even","mid","odd"];

for(var i=0 ; i < quoteBox.length; i++) {
   quoteBox[i].classList.add(classes[i%3]);
}

/***********************/

var $item = $('.carousel-item');

$item.addClass('full-screen');

var $numberofSlides = $('.carousel-item').length;
var $currentSlide = Math.floor((Math.random() * $numberofSlides));

$('.carousel-indicators li').each(function(){
  var $slideValue = $(this).attr('data-slide-to');
  if($currentSlide == $slideValue) {
    $(this).addClass('active');
    $item.eq($slideValue).addClass('active');
  } else {
    $(this).removeClass('active');
    $item.eq($slideValue).removeClass('active');
  }
});

$('.carousel').carousel({
  interval: false,
  pause: "false"
});




// find the pathname
var urlPath = window.location.pathname;
//convert to lowercase
urlPath.match(/\/(.*?)(\+|$)/)[1].toLowerCase();
// remove the first character
urlPath = urlPath.replace(/\//g, '-').slice(1);
//remove file extension
urlPath = urlPath.replace(/\.[^/.]+$/, "");
// add class to body
$(document.body).addClass(urlPath);
// If body has no class its propably the homepage
$('body:not([class])').addClass('home');
console.log(urlPath);



function openModal(rowID){
  $('#txtFile').val(rowID);
  $('#downloadModal').modal('show');
}
