$('.navEl').on('click',function(e) {
   e.preventDefault();
   var target = this.hash;
   var $target = $(target);

   if( $('#menuItems,#scrim').css('display') == 'block' ){
       $('#menuItems,#scrim').css('display', 'none');
   }

   $('html, body').stop().animate({
    'scrollTop': $target.offset().top
    }, 500, 'swing', function () {
    window.location.hash = target;
   });
});
